<sat-popover #popover xAlign="start" yAlign="start">
  <div class="popover-container columns-menu" [attr.data-id]="'overlay_table_settings'">
    <h2 class="header">Table settings</h2>

    <h4 class="items-label">Items per page</h4>
    <mat-button-toggle-group [value]="pageSize" (valueChange)="pageSizeChanged.emit($event)">
      <mat-button-toggle
        *ngFor="let pageSizeOption of pageSizeOptions; trackBy: trackByIdentity"
        fxFlex="1 1 auto"
        [value]="pageSizeOption">
        {{ pageSizeOption }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-divider></mat-divider>

    <ng-container *ngIf="columnsConfig?.length">
      <share-columns-config [columnsConfig]="columnsConfig" (columnsConfigChanged)="columnsConfigChanged.emit($event)">
      </share-columns-config>

      <mat-divider></mat-divider>
    </ng-container>

    <div class="buttons" fxLayoutAlign="center center">
      <button class="text-button" mat-button (click)="resetClicked.emit()">Restore defaults</button>
    </div>
  </div>
</sat-popover>

<button
  class="trigger-button"
  aria-label="Table settings"
  mat-icon-button
  matTooltip="Table settings"
  [satPopoverAnchor]="popover"
  [sharePopoverTrigger]="popover">
  <mat-icon>tune</mat-icon>
</button>
