import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnConfig } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSettingsComponent {
  @Input()
  public columnsConfig: ColumnConfig[] = [];

  @Input()
  public pageSize: number;

  @Input()
  public pageSizeOptions: number[] = [10, 25, 50, 100];

  @Output()
  public readonly columnsConfigChanged = new EventEmitter<ColumnConfig[]>();

  @Output()
  public readonly pageSizeChanged = new EventEmitter<number>();

  @Output()
  public readonly resetClicked = new EventEmitter<void>();

  public readonly trackByIdentity = trackByIdentity;
}
