import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DateFormatAlias, UserDateService } from './user-date.service';

@Injectable()
export class DateAdapterMat extends NativeDateAdapter {
  constructor(
    private readonly userDateService: UserDateService,
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform
  ) {
    super(matDateLocale, platform);
  }

  public override getFirstDayOfWeek(): number {
    return this.userDateService.dateSettings.startOfWeek;
  }

  public override today(): Date {
    return this.userDateService.applyTz(new Date());
  }

  public override format(date: Date, _displayFormat: unknown): string {
    return this.userDateService.formatDate(date, { format: DateFormatAlias.DATE_ONLY });
  }

  public override parse(value: unknown): Date | null {
    if (typeof value === 'string') {
      return this.userDateService.parse(value);
    }
    return null;
  }
}
