import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ToastrModule } from 'ngx-toastr';
import { DateAdapterMat } from '@neuralegion/core';
import { DataIdsModule } from '@neuralegion/data-ids';
import {
  AppHeaderComponent,
  BodyCodeViewerComponent,
  BreadcrumbComponent,
  CalendarRangePickerComponent,
  CodeEditorComponent,
  CodeHexVirtualScrollComponent,
  CodeViewerComponent,
  CodeVirtualScrollComponent,
  ColumnsConfigComponent,
  ConfigSourceChipComponent,
  ConfirmModalComponent,
  CopyButtonComponent,
  CopyMenuItemComponent,
  CopyableIdComponent,
  CustomNumberSelectComponent,
  CustomStepperComponent,
  DateRangeFilterComponent,
  DateRangeFilterControlComponent,
  DateRangesPredefinedComponent,
  DiffViewerComponent,
  EditableSelectComponent,
  ExternalFiltrationSelectComponent,
  FileUploadingComponent,
  FilterableMultiLineSelectComponent,
  FilterableSelectComponent,
  HeaderListItemControlComponent,
  HeadersEditorComponent,
  HeadersViewerComponent,
  HealthIndicatorComponent,
  InfoTooltipComponent,
  InputFileComponent,
  InputNumberComponent,
  IssuesQuantityComponent,
  JobStatusChipComponent,
  KeyValueListItemControlComponent,
  LabelsSelectComponent,
  ListControlComponent,
  MfaQrCodeComponent,
  NoteComponent,
  NumberComparatorControlComponent,
  NumberCompareFilterControlComponent,
  OpenNewTabLinkComponent,
  PageRefreshButtonComponent,
  PaginatorComponent,
  PasswordRepeatComponent,
  PayloadCopyAsDropdownComponent,
  PendingEngineIndicatorComponent,
  ScopesControlComponent,
  SecretFieldComponent,
  SelectFilterControlComponent,
  ServerFilterableSelectComponent,
  SettingsErrorBarComponent,
  SettingsTabLabelComponent,
  ShowMoreArrayComponent,
  ShowMoreKeyValueComponent,
  SidebarComponent,
  SingleInputListItemControlComponent,
  SnackBarComponent,
  SpinnerButtonComponent,
  SpinnerComponent,
  TabViewSettingsComponent,
  TableFilterComponent,
  TableSearchFilterComponent,
  TableSettingsComponent,
  TableTextFilterControlComponent,
  TabsConfigComponent,
  TextFilterComponent,
  TextFilterControlComponent,
  ThemeSwitcherComponent,
  ToggleableLimitComponent,
  ToolbarComponent
} from '../components';
import { EntryComponent, NavItemComponent, TableFiltersComponent } from '../containers';
import {
  AutofocusDirective,
  CloseAutocompletePanelOnScrollDirective,
  CloseOnBackdropClickDirective,
  CopyMenuItemDirective,
  DynamicControlDirective,
  ElementTextAdditionalItemsDirective,
  ElementTextDirective,
  FilterDataSourceDirective,
  FilterableSelectControlsKeydownDirective,
  FilterableSelectOptionsFocusDirective,
  FilterableSelectPanelFocusDirective,
  FilterableSelectRoleDialogDirective,
  FormNativeElementDirective,
  FormStatusDirective,
  InputBlurOnEnterDirective,
  LinkNavigateDirective,
  MatErrorOverrideDirective,
  MatErrorTextDirective,
  PopoverTriggerDirective,
  ResizeColumnsDirective,
  TableFiltersStateDirective,
  TableRowLinkDirective,
  TableRowSelectedDirective,
  TreeSizeUpdateDirective
} from '../directives';
import {
  AsyncSelectFilterItemsPipe,
  AttackParamLocationPipe,
  BooleanPipe,
  BytesPipe,
  ComparisonOperatorNamePipe,
  CustomDatePipe,
  DateFormatLabelPipe,
  DateRangePipe,
  DefaultsPipe,
  DiscoveryTypePipe,
  DiscoveryTypesShortPipe,
  DurationPipe,
  ElapsedTimePipe,
  ExtraSignPipe,
  FilterControlPipe,
  FilterLabelPipe,
  FilterStatusLabelPipe,
  FilterValuePipe,
  FindFilterPipe,
  FindIndexPipe,
  FindPipe,
  FullNamePipe,
  HeaderConfigPipe,
  HighlightPipe,
  HostPipe,
  IsNumberPipe,
  JobStatusIconPipe,
  JobStatusMapPipe,
  JobStatusPipe,
  JoinPipe,
  MapByIdPipe,
  MaxPipe,
  NamePipe,
  OrganizationPermissionPipe,
  PaginatorLabelPipe,
  PluckPipe,
  ProviderTypePipe,
  QuotaTypeIconPipe,
  QuotaTypePipe,
  QuotesPipe,
  ReplacePipe,
  RepositoryNamePipe,
  RequestExclusionsPipe,
  SafeBlobUrlPipe,
  SafeUrlPipe,
  SectionErrorPipe,
  SelectTriggerLabelPipe,
  ServiceNamePipe,
  SessionPipe,
  SettingsTabSectionStatusPipe,
  SimplePluralPipe,
  SomePipe,
  SummaryBucketsDetailsPipe,
  TableSortDirectionPipe,
  TimeAgoPipe,
  TimeFormatLabelPipe,
  TimeZoneLabelPipe,
  TzDatePipe,
  UniquePipe,
  VerbPastTensePipe,
  WeekdayPipe
} from '../pipes';
import { SatPopoverModule } from '../sat-popover';
import { ShareEffects } from '../store';
import { MatModule } from './mat.module';

const exported = [
  AutofocusDirective,
  CloseAutocompletePanelOnScrollDirective,
  CloseOnBackdropClickDirective,
  CopyMenuItemDirective,
  DynamicControlDirective,
  ElementTextAdditionalItemsDirective,
  ElementTextDirective,
  FilterDataSourceDirective,
  FilterableSelectControlsKeydownDirective,
  FilterableSelectOptionsFocusDirective,
  FilterableSelectPanelFocusDirective,
  FilterableSelectRoleDialogDirective,
  FormNativeElementDirective,
  FormStatusDirective,
  InputBlurOnEnterDirective,
  LinkNavigateDirective,
  MatErrorOverrideDirective,
  MatErrorTextDirective,
  PopoverTriggerDirective,
  ResizeColumnsDirective,
  TableFiltersStateDirective,
  TableRowLinkDirective,
  TableRowSelectedDirective,
  TreeSizeUpdateDirective,

  BodyCodeViewerComponent,
  CodeViewerComponent,
  CalendarRangePickerComponent,
  CodeEditorComponent,
  CodeHexVirtualScrollComponent,
  CodeVirtualScrollComponent,
  ColumnsConfigComponent,
  ConfigSourceChipComponent,
  CopyButtonComponent,
  CopyMenuItemComponent,
  CopyableIdComponent,
  CustomNumberSelectComponent,
  CustomStepperComponent,
  DateRangeFilterComponent,
  DateRangeFilterControlComponent,
  DateRangesPredefinedComponent,
  DiffViewerComponent,
  EditableSelectComponent,
  ExternalFiltrationSelectComponent,
  FileUploadingComponent,
  FilterableMultiLineSelectComponent,
  FilterableSelectComponent,
  HeaderListItemControlComponent,
  HeadersEditorComponent,
  HeadersViewerComponent,
  HealthIndicatorComponent,
  InfoTooltipComponent,
  InputFileComponent,
  InputNumberComponent,
  IssuesQuantityComponent,
  JobStatusChipComponent,
  KeyValueListItemControlComponent,
  LabelsSelectComponent,
  ListControlComponent,
  MfaQrCodeComponent,
  NoteComponent,
  NumberComparatorControlComponent,
  NumberCompareFilterControlComponent,
  OpenNewTabLinkComponent,
  PaginatorComponent,
  PasswordRepeatComponent,
  PayloadCopyAsDropdownComponent,
  PendingEngineIndicatorComponent,
  ScopesControlComponent,
  SecretFieldComponent,
  SelectFilterControlComponent,
  ServerFilterableSelectComponent,
  SettingsErrorBarComponent,
  SettingsTabLabelComponent,
  ShowMoreArrayComponent,
  ShowMoreKeyValueComponent,
  SingleInputListItemControlComponent,
  SpinnerButtonComponent,
  SpinnerComponent,
  TabViewSettingsComponent,
  TableFilterComponent,
  TableSearchFilterComponent,
  TableSettingsComponent,
  TableTextFilterControlComponent,
  TabsConfigComponent,
  TextFilterComponent,
  TextFilterControlComponent,
  ThemeSwitcherComponent,
  ToggleableLimitComponent,
  ToolbarComponent,

  EntryComponent,
  NavItemComponent,
  TableFiltersComponent,

  AsyncSelectFilterItemsPipe,
  AttackParamLocationPipe,
  BooleanPipe,
  BytesPipe,
  ComparisonOperatorNamePipe,
  CustomDatePipe,
  DateFormatLabelPipe,
  DateRangePipe,
  DefaultsPipe,
  DiscoveryTypePipe,
  DiscoveryTypesShortPipe,
  DurationPipe,
  ElapsedTimePipe,
  ExtraSignPipe,
  FilterControlPipe,
  FilterLabelPipe,
  FilterStatusLabelPipe,
  FilterValuePipe,
  FindFilterPipe,
  FindIndexPipe,
  FindPipe,
  FullNamePipe,
  HeaderConfigPipe,
  HighlightPipe,
  HostPipe,
  IsNumberPipe,
  JobStatusIconPipe,
  JobStatusMapPipe,
  JobStatusPipe,
  JoinPipe,
  MapByIdPipe,
  MaxPipe,
  NamePipe,
  OrganizationPermissionPipe,
  PaginatorLabelPipe,
  PluckPipe,
  ProviderTypePipe,
  QuotaTypeIconPipe,
  QuotaTypePipe,
  QuotesPipe,
  ReplacePipe,
  RepositoryNamePipe,
  RequestExclusionsPipe,
  SafeBlobUrlPipe,
  SafeUrlPipe,
  SectionErrorPipe,
  SelectTriggerLabelPipe,
  ServiceNamePipe,
  SessionPipe,
  SettingsTabSectionStatusPipe,
  SimplePluralPipe,
  SomePipe,
  SummaryBucketsDetailsPipe,
  TableSortDirectionPipe,
  TimeAgoPipe,
  TimeFormatLabelPipe,
  TimeZoneLabelPipe,
  TzDatePipe,
  UniquePipe,
  VerbPastTensePipe,
  WeekdayPipe
];

@NgModule({
  imports: [
    EffectsModule.forFeature([ShareEffects]),
    CommonModule,
    A11yModule,
    RouterModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    DragDropModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    FlexLayoutModule,
    MatModule,
    SatPopoverModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    DataIdsModule
  ],
  declarations: [
    AppHeaderComponent,
    BreadcrumbComponent,
    CodeViewerComponent,
    ConfirmModalComponent,
    PageRefreshButtonComponent,
    SidebarComponent,
    SnackBarComponent,

    ...exported
  ],
  exports: [
    A11yModule,
    CommonModule,
    ClipboardModule,
    ReactiveFormsModule,
    DragDropModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    FlexLayoutModule,
    MatModule,
    SatPopoverModule,
    DataIdsModule,

    ...exported
  ],
  providers: [
    ComparisonOperatorNamePipe,
    DateRangePipe,
    { provide: DateAdapter, useClass: DateAdapterMat }
  ]
})
export class ShareModule {}
